import { Link } from "gatsby"
import React, { useRef } from "react"
import Carousel from "../components/carousel"
import ContactForm from "../components/contact-form"
import MapChart from "../components/MapChart"
import right from "../images/icons/right.svg"
import video1 from "../images/video1.mp4"
import video2 from "../images/video2.mp4"
import video3 from "../images/video3.mp4"
import dealerImg from "../images/home/Group 429.png"
import architechtImg from "../images/home/Group 434.png"
import homeOwnersImg from "../images/home/Group 437.png"
import StandardLayout from "../layouts/standard"
import AssociationForm from "./components/AssociationForm"

const IndexPage = () => {
  const { homeSliders } = require("../data.json")
  const vidRef = useRef()
  const vid2Ref = useRef()
  const vid3Ref = useRef()
  // const pauseRef = useRef()

  // const play = () => {
  //   vidRef.current.play()
  //   vid2Ref.current.play()
  //   vid3Ref.current.play()
  // }
  // const pause = () => {
  //   vidRef.current.pause()
  //   vid2Ref.current.pause()
  //   vid3Ref.current.pause()
  // }

  return (
    <StandardLayout title="Stylam">
      <div className="hero-slider">
        <div
          class="uk-position-relative uk-visible-toggle uk-light"
          tabindex="-1"
          data-uk-slideshow="ratio: false; , autoplay: true"
        >
          <ul
            class="uk-slideshow-items"
            data-uk-height-viewport="offset-top: true; offset-bottom: 10"
          >
            {homeSliders.map(slider => (
              <li>
                <div
                  className="uk-inline"
                  style={{ width: "100%", height: "100%" }}
                >
                  <img src={slider.image.publicURL} alt="" data-uk-cover />

                  <div class="uk-overlay-primary uk-position-cover"></div>
                  <div className="uk-position-large uk-position-center-left uk-dark uk-margin-large-left">
                    <div className="uk-h2 uk-heading uk-margin-remove-bottom">
                      {slider.title_small}
                    </div>

                    <div className="uk-h1 uk-heading uk-margin-remove-top">
                      {slider.title}
                    </div>
                    <div className="uk-width-medium">{slider.subtitle}</div>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <div className="uk-position-large uk-position-bottom-right uk-width-1-1 ">
            <div className="uk-flex uk-flex-middle uk-flex-right uk-width-1-1">
              {/* <a className="pv2 ph3 ba" href="#" uk-slideshow-item="previous">
            <img src={left} alt=""/>
          </a> */}
              <a className="pv2 ph3 ba" href="#" uk-slideshow-item="next">
                <img src={right} alt="" />
              </a>
            </div>

            <ul class="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>
          </div>
        </div>
      </div>
      <section>
        <div className="dealer-cont">
          <div className="dealer-img-cont">
            <img src={dealerImg} />
          </div>
          <div className="dealer-text-cont">
            <div>
              Dealers &<br /> Distributors
            </div>
            <div>
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from 45 BC,
              making it over 2000 years old. Richard McClintock, a Latin
              professor at Hampden.
            </div>
            <button
              className="new-maroon-btn"
              uk-toggle="target: #get-associated-modal"
            >
              Get Associated
            </button>
          </div>
          <div></div>
        </div>
        <div className="dealer-cont bg-white">
          <div className="dealer-text-cont2">
            <div>
              Architects
              <br /> & interior
              <br /> Designer
            </div>
            <div>
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from 45 BC,
              making it over 2000 years old. Richard McClintock, a Latin
              professor at Hampden-Sydney College in Virginia.
            </div>
          </div>
          <div className="dealer-img-cont2">
            <img src={architechtImg} />
          </div>
          <div></div>
        </div>
        <div className="homwowners-cont">
          <img src={homeOwnersImg} />
          <div>
            <div>Home Owner</div>
            <Link to="/home-owners" className="uk-link-reset">
              <button className="new-maroon-btn">Know More</button>
            </Link>
          </div>
        </div>
      </section>
      <section className="uk-section">
        <div className="uk-container">
          <div className="uk-h1">Product Assortment</div>
          <div>
            Exquisite. Unbeatable Collection of Decorative Laminates. Specialty
            Surfaces. Value Added PU+ Lacquer Surface. Prelam MDF Panels & Solid
            Surfaces. The go-to choice of Architects & Designers.
            <span>
              <Link
                to="/products"
                className="uk-link-text"
                style={{ color: "var(--primary-color)" }}
              >
                {" "}
                Read More
              </Link>
            </span>
          </div>
          <div className="uk-margin-medium">
            <Carousel></Carousel>
          </div>
        </div>
      </section>
      {/* <section className="uk-section">
        <div className="uk-container">
          <div className="uk-grid">
            <div className="uk-width-1-3@s uk-width-1-1">
              <div className="uk-h1">
              About Us
              </div>
              <p className="uk-width-xlarge">
              Were one of World's Leading Manufacturer & Exporter of Decorative Laminates. Acrylic/Modified Solid Surfaces & Prelam Panels. 
              </p>
              <p className="uk-text-bold uk-width-xlarge">
                We constantly strive to develop new Home Decor Designs complying with Global Industry Trends
              </p>
              <Link to="/gallery" className="uk-button uk-button-primary">
                <span style={{color: "white"}}>Learn More</span>
              </Link>
            </div>
            <div className="uk-width-expand">
                <div className="uk-grid uk-grid-medium uk-flex-wrap uk-flex-center  uk-child-width-1-3@s uk-child-width-1-2" data-uk-grid>
                  <div>
                    <div className="uk-card uk-card-default uk-padding-small uk-text-center">
                        <img src={awardImage} alt=""/>
                        <div className="uk-margin-medium-top uk-text-meta">Granex & Marvella Offer Outstanding Aesthetics And Functionality Without The Shortcomings Of Natural Stone.</div>
                    </div>
                  </div>
                  <div>
                    <div className="uk-card uk-card-default uk-padding-small uk-text-center">
                        <img src={awardImage} alt=""/>
                        <div className="uk-margin-medium-top uk-text-meta">Granex & Marvella Offer Outstanding Aesthetics And Functionality Without The Shortcomings Of Natural Stone.</div>
                    </div>
                  </div>
                  <div>
                    <div className="uk-card uk-card-default uk-padding-small uk-text-center">
                        <img src={awardImage} alt=""/>
                        <div className="uk-margin-medium-top uk-text-meta">Granex & Marvella Offer Outstanding Aesthetics And Functionality Without The Shortcomings Of Natural Stone.</div>
                    </div>
                  </div>
                  <div>
                    <div className="uk-card uk-card-default uk-padding-small uk-text-center">
                        <img src={awardImage} alt=""/>
                        <div className="uk-margin-small-top uk-text-meta">Granex & Marvella Offer Outstanding Aesthetics And Functionality Without The Shortcomings Of Natural Stone.</div>
                    </div>
                  </div>
                  <div>
                    <div className="uk-card uk-card-default uk-padding-small uk-text-center">
                        <img src={awardImage} alt=""/>
                        <div className="uk-margin-small-top uk-text-meta">Granex & Marvella Offer Outstanding Aesthetics And Functionality Without The Shortcomings Of Natural Stone.</div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="uk-section uk-section-muted ">
        <div className="uk-container">
          <div>
            <div className="uk-h1">Industrial Solution</div>
            <p className="uk-width-auto">
              We are one of World's Leading Manufacturer & Exporter of
              Decorative Laminates. Acrylic/Modified Solid Surfaces & Prelam
              Panels.
            </p>
          </div>
          <div>
            <ul class="uk-child-width-1-3@s uk-child-width-1-1 uk-grid-match uk-grid">
              <li>
                <div className="uk-card uk-card-secondary uk-box-shadow-hover-small">
                  <div className="uk-width-1-1">
                    <video
                      src={video1}
                      controls
                      playsinline="false"
                      muted
                      onMouseOver={() => vidRef.current.play()}
                      onMouseLeave={() => vidRef.current.pause()}
                      ref={vidRef}
                      loop
                      // data-uk-video="autoplay: inview"
                    ></video>
                  </div>
                  <div className="uk-padding-small">
                    <div className="uk-text-lead">#KuchNahiDikhega</div>
                    <p className="uk-margin-remove-top">
                      We found your life partner & you couldn’t have asked for
                      better!
                    </p>
                    {/* <Link to="/gallery" className="uk-button uk-button-primary color-white">
                            Read More
                          </Link> */}
                  </div>
                </div>
              </li>
              <li>
                <div className="uk-card uk-card-secondary uk-box-shadow-hover-small">
                  <div className="uk-width-1-1">
                    <video
                      src={video2}
                      controls
                      playsinline="false"
                      muted
                      onMouseOver={() => vid2Ref.current.play()}
                      onMouseLeave={() => vid2Ref.current.pause()}
                      ref={vid2Ref}
                      loop
                      // data-uk-video="autoplay: inview"
                    ></video>
                  </div>
                  <div className="uk-padding-small">
                    <div className="uk-text-lead">#KuchNahiDikhega</div>
                    <p className="uk-margin-remove-top">
                      Is love really blind? Test it out for yourself!
                    </p>
                    {/* <Link to="/gallery" className="uk-button uk-button-primary color-white">
                            Read More
                          </Link> */}
                  </div>
                </div>
              </li>
              <li>
                <div className="uk-card uk-card-secondary uk-box-shadow-hover-small">
                  <div className="uk-width-1-1">
                    <video
                      src={video3}
                      controls
                      playsinline="false"
                      onMouseOver={() => vid3Ref.current.play()}
                      onMouseLeave={() => vid3Ref.current.pause()}
                      ref={vid3Ref}
                      muted
                      loop
                      // data-uk-video="autoplay: inview"
                    ></video>
                  </div>
                  <div className="uk-padding-small">
                    <div className="uk-text-lead">#KuchNahiDikhega</div>
                    <p className="uk-margin-remove-top">
                      No possibility of tampering on this pitch! Hit a six with
                      Stylam.
                    </p>
                    {/* <Link to="/gallery" className="uk-button uk-button-primary color-white">
                            Read More
                          </Link> */}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="uk-section" style={{ paddingBottom: "0" }}>
        <div className="uk-container">
          <div>
            <div className="uk-h1">
              A WORLD OF <br /> INFINITE POSSIBILITIES
            </div>
            <p className="uk-width-xlarge">
              <span className="color-primary">65+ Countries</span> Worldwide, We
              Expanding and spreading more...
            </p>
            {/* <div className="uk-width-xlarge uk-flex uk-flex-between uk-flex-wrap">
                <div className="uk-margin-small-bottom">
                  <div className="uk-text-lead color-primary">
                  1200+
                  </div>
                  <div className="uk-text-meta">
                  Designs
                  </div>
                </div>
                <div>
                  <div className="uk-text-lead color-primary">
                  70000+
                  </div>
                  <div className="uk-text-meta">
                  Projects completed
                  </div>
                </div>
                <div>
                  <div className="uk-text-lead color-primary">
                  100000+
                  </div>
                  <div className="uk-text-meta">
                  Satisfied customers around the world                  
                  </div>
                </div>
              </div> */}
          </div>
          <div className="uk-margin uk-padding-medium uk-text-center uk-margin-large-top">
            <MapChart />
          </div>
        </div>
      </section>
      <section className="uk-section ">
        <div className="uk-container">
          <div>
            <div className="uk-h1">GET IN TOUCH</div>
            <p className="uk-width-xlarge">
              <span className="color-primary"></span> At{" "}
              <span className="color-primary">Stylam</span>, we are dedicated to
              being available to client needs; please feel free to reach out to
              us for any queries.
            </p>
          </div>
          <div id="get-associated-modal" data-uk-modal>
            <div class="uk-modal-dialog">
              <button
                class="uk-modal-close-default m-close"
                type="button"
                uk-close
              >
                &#10006;
              </button>
              <AssociationForm />
            </div>
          </div>
          <div className="uk-margin">
            <div className="uk-card uk-card-default uk-padding-large">
              <div
                className="uk-grid uk-child-width-1-1 uk-child-width-1-2@s"
                data-uk-grid
              >
                <div>
                  <ContactForm></ContactForm>
                </div>
                <div>
                  <iframe
                    src="https://maps.google.com/maps?q=Stylam%20S.C.O.%2014,%20Sector%207-%20C,%20Madhya%20Marg,%20Chandigarh%20160019&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    width="100%"
                    height="510"
                    allowfullscreen=""
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </StandardLayout>
  )
}

export default IndexPage

{
  /* <iframe width="600" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=Stylam%20S.C.O.%2014,%20Sector%207-%20C,%20Madhya%20Marg,%20Chandigarh%20160019&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe> */
}
