import React, { useState } from "react"
import { useForm } from "react-hook-form"
import axios from "axios"
import { backendHost } from "../../config.js"

export default function ContactForm() {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()
  const [disableForm, setDisableForm] = useState(false)

  const submitForm = data => {
    setDisableForm(true)
    axios
      .post(backendHost + "/contact", data)
      .then(res => resolve("Successfully submitted!"))
      .catch(res => resolve("An error occured"))
    const resolve = msg => {
      alert(msg)
      setDisableForm(false)
    }
  }

  const printError = msg => (
    <div className="color-primary">{msg || "This is a required field"}</div>
  )

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <fieldset class="uk-fieldset">
        <div
          className="uk-grid uk-child-width-1-2@s uk-child-width-1-1"
          data-uk-grid
        >
          <div>
            <div class="uk-margin">
              <label class="uk-form-label2 color-primary" for="full-name">
                Name
              </label>
              <input
                class="uk-input"
                {...register("name", { required: true })}
                disabled={disableForm}
                type="text"
                placeholder="First & Last Name"
              />
              {errors.name && printError()}
            </div>
          </div>

          <div>
            <div class="uk-margin">
              <label class="uk-form-label2 color-primary" for="email">
                Email
              </label>
              <input
                class="uk-input"
                {...register("email", {
                  required: true,
                  pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                })}
                disabled={disableForm}
                type="email"
                placeholder="yourname@website.com"
              />
              {errors.email && printError("Please enter a valid email")}
            </div>
          </div>
        </div>

        <div
          className="uk-grid uk-child-width-1-2@s uk-child-width-1-1"
          data-uk-grid
        >
          <div>
            <div class="uk-margin">
              <label class="uk-form-label2 color-primary" for="full-name">
                Phone
              </label>
              <input
                class="uk-input"
                {...register("phone", {
                  required: true,
                  pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                })}
                disabled={disableForm}
                placeholder="+91 9XXXXXXXX9"
              />
              {errors.phone && printError("Please enter a valid phone number")}
            </div>
          </div>
          <div>
            <div class="uk-margin">
              <label class="uk-form-label2 color-primary" for="full-name">
                Country
              </label>
              <input
                class="uk-input"
                {...register("country", { required: true })}
                disabled={disableForm}
                placeholder="India"
              />
              {errors.country && printError()}
            </div>
          </div>
        </div>

        <div
          className="uk-grid uk-child-width-1-2@s uk-child-width-1-1"
          data-uk-grid
        >
          <div>
            <div class="uk-margin">
              <label class="uk-form-label2 color-primary" for="full-name">
                State
              </label>
              <input
                class="uk-input"
                {...register("state", { required: true })}
                disabled={disableForm}
                placeholder="Delhi"
              />
              {errors.state && printError()}
            </div>
          </div>
          <div>
            <div class="uk-margin">
              <label class="uk-form-label2 color-primary" for="full-name">
                City
              </label>
              <input
                class="uk-input"
                {...register("city", { required: true })}
                disabled={disableForm}
                placeholder="New Delhi"
              />
              {errors.city && printError()}
            </div>
          </div>
        </div>

        <div className="uk-margin">
          <label class="uk-form-label2 color-primary" for="message">
            Product you are looking for
          </label>
          <textarea
            className="uk-textarea"
            {...register("product", { required: true })}
            disabled={disableForm}
            cols="30"
            rows="5"
            placeholder="Your message"
          ></textarea>
          {errors.product && printError()}
        </div>

        <div className="uk-margin">
          <button className="uk-button uk-button-primary new-btn">Submit Now</button>
        </div>
      </fieldset>
    </form>
  )
}
