import React from 'react';
import { Link } from "gatsby";
import homeCarouselImage from '../images/home/home-carousel-image-1.jpg'
import { imageExtensions, videoExtensions } from '../utils';

export default function Header() {
    const { categories } = require('../data_file.json');
    return (
        <div>
            <div class="uk-slider-container-offset" data-uk-slider>

            <div class="uk-position-relative uk-light" tabindex="-1">

                <ul class="uk-slider-items uk-child-width-1-3@s uk-grid uk-grid-match">
                    {categories.map(cat => {
                        let thumbnailIs = 'image';
                        if (cat.thumbnail) thumbnailIs = imageExtensions.find(e => e === cat.thumbnail.ext) ? 'image' :
                                            videoExtensions.find(e => e === cat.thumbnail.ext) ? 'video' :
                                            '';
                        return <li>
                            <div class="uk-card uk-card-default">
                                <div className="uk-position-relative overflow-hidden" style={{ background: '#222', height: '200px' }}>
                                    {thumbnailIs == 'image' ? <img className="uk-position-center h-100" style={{ width: 'auto', maxWidth: 'none' }} src={cat.thumbnail && cat.thumbnail.publicURL} alt=""/> :
                                     thumbnailIs == 'video' ? <video className="uk-position-center h-100" style={{ width: 'auto', maxWidth: 'none' }} src={cat.thumbnail && cat.thumbnail.publicURL} /> :
                                     ''}
                                </div>
                                <div class="flex uk-flex-between uk-flex-middle pv3 ph4">
                                    <div className="w-70">
                                        <h3 class="uk-card-title uk-margin-remove-bottom" style={{fontSize: "1.2rem", textTransform: "capitalize"}}>{cat.title}</h3>
                                    </div>
                                    <div className="w-30 uk-text-right">
                                        <div className="">
                                            <Link to={'/products/' + cat.url}>
                                                <button className="icon-button black">
                                                <svg width="14" height="24" viewBox="0 0 14 24" xmlns="http://www.w3.org/2000/svg"><polyline fill="none" stroke="#000" stroke-width="1.4" points="1.225,23 12.775,12 1.225,1 "></polyline></svg>
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    })}
                </ul>

                

            </div>

            <div className="uk-margin-medium">
                
                <div className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle">
                    <div>
                        <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
                    </div>
                    <div>
                        <a href="#" className="pv3 ph3 ba b--black" data-uk-slider-item="previous">
                        <svg width="14" height="24" viewBox="0 0 14 24" xmlns="http://www.w3.org/2000/svg"><polyline fill="none" stroke="#000" stroke-width="1.4" points="12.775,1 1.225,12 12.775,23 "></polyline></svg>
                        </a>
                        <a className="uk-margin-small-left pv3 ph3 ba b--black" href="#" data-uk-slider-item="next">
                        <svg width="14" height="24" viewBox="0 0 14 24" xmlns="http://www.w3.org/2000/svg"><polyline fill="none" stroke="#000" stroke-width="1.4" points="1.225,23 12.775,12 1.225,1 "></polyline></svg>
                        </a>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}