import React from "react"
import "./style.css"

export default function AssociationForm() {
  return (
    <form className="association-form">
      <div className="heading">Get Associated</div>

      <hr style={{width: "100%"}}/>

      <div className="form-group">
        <label>
          Full Name
          <input
            className="input-field"
            type="text"
            autoComplete="name"
            required
          />
        </label>

        <label>
          Email Address*
          <input
            className="input-field"
            type="email"
            autoComplete="email"
            required
          />
        </label>

        <label>
          Phone Number*
          <input className="input-field" type="text" autoComplete="phone" />
        </label>

        <label>
          Country*
          <input className="input-field" type="text" autoComplete="country" />
        </label>

        <label>
          State*
          <input className="input-field" type="text" autoComplete="state" />
        </label>

        <label>
          City*
          <input className="input-field" type="text" autoComplete="city" />
        </label>

        <label style={{ flexDirection: "row", alignItems: "center" }}>
          <input type="checkbox" />&nbsp; I agree to the <b>Terms and Conditions.</b>
        </label>
      </div>
      <button type="submit">SUBMIT</button>
    </form>
  )
}
