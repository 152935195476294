import React, { useState } from "react"
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps"
import ReactTooltip from "react-tooltip"
import { PatternLines, PatternCircles } from "@vx/pattern"

const geoUrl = require("../assets/world-110m.json")
const countries = [
  {
    country: "VE",
    latitude: 6.42375,
    longitude: -66.58973,
    name: "Venezuela",
  },
  {
    country: "DZ",
    latitude: 28.033886,
    longitude: 1.659626,
    name: "Algeria",
  },
  {
    country: "AR",
    latitude: -38.416097,
    longitude: -63.616672,
    name: "Argentina",
  },
  {
    country: "AU",
    latitude: -25.274398,
    longitude: 133.775136,
    name: "Australia",
  },
  {
    country: "AT",
    latitude: 47.516231,
    longitude: 14.550072,
    name: "Austria",
    offsetX: -3,
  },
  {
    country: "BH",
    latitude: 25.930414,
    longitude: 50.637772,
    name: "Bahrain",
  },
  {
    country: "BD",
    latitude: 23.684994,
    longitude: 90.356331,
    name: "Bangladesh",
  },
  {
    country: "BE",
    latitude: 50.503887,
    longitude: 4.469936,
    name: "Belgium",
  },
  {
    country: "BT",
    latitude: 27.514162,
    longitude: 90.433601,
    name: "Bhutan",
  },
  {
    country: "BO",
    latitude: -16.290154,
    longitude: -63.588653,
    name: "Bolivia",
  },
  {
    country: "KH",
    latitude: 12.565679,
    longitude: 104.990963,
    name: "Cambodia",
  },
  {
    country: "CM",
    latitude: 7.369722,
    longitude: 12.354722,
    name: "Cameroon",
  },
  {
    country: "CA",
    latitude: 56.130366,
    longitude: -106.346771,
    name: "Canada",
  },
  {
    country: "CL",
    latitude: -35.675147,
    longitude: -71.542969,
    name: "Chile",
  },
  {
    country: "CO",
    latitude: 4.570868,
    longitude: -74.297333,
    name: "Colombia",
  },
  {
    country: "CR",
    latitude: 9.748917,
    longitude: -83.753428,
    name: "Costa Rica",
  },
  {
    country: "HR",
    latitude: 45.1,
    longitude: 15.2,
    name: "Croatia",
  },
  {
    country: "CY",
    latitude: 35.126413,
    longitude: 33.429859,
    name: "Cyprus",
  },
  {
    country: "CZ",
    latitude: 49.817492,
    longitude: 15.472962,
    name: "Czech Republic",
  },
  {
    country: "EC",
    latitude: -1.831239,
    longitude: -78.183406,
    name: "Ecuador",
  },
  {
    country: "EG",
    latitude: 26.820553,
    longitude: 30.802498,
    name: "Egypt",
  },
  {
    country: "EE",
    latitude: 58.595272,
    longitude: 25.013607,
    name: "Estonia",
    offsetX: 5,
  },
  {
    country: "ET",
    latitude: 9.145,
    longitude: 40.489673,
    name: "Ethiopia",
  },
  {
    country: "FI",
    latitude: 61.92411,
    longitude: 25.748151,
    name: "Finland",
  },
  {
    country: "GE",
    latitude: 42.315407,
    longitude: 43.356892,
    name: "Georgia",
  },
  {
    country: "DE",
    latitude: 51.165691,
    longitude: 10.451526,
    name: "Germany",
  },
  {
    country: "GT",
    latitude: 15.783471,
    longitude: -90.230759,
    name: "Guatemala",
  },
  {
    country: "HN",
    latitude: 15.199999,
    longitude: -86.241905,
    name: "Honduras",
  },
  {
    country: "HU",
    latitude: 47.162494,
    longitude: 19.503304,
    name: "Hungary",
  },
  {
    country: "ID",
    latitude: -0.789275,
    longitude: 113.921327,
    name: "Indonesia",
  },
  {
    country: "IL",
    latitude: 31.046051,
    longitude: 34.851612,
    name: "Israel",
  },
  {
    country: "IT",
    latitude: 41.87194,
    longitude: 12.56738,
    name: "Italy",
  },
  {
    country: "KZ",
    latitude: 48.019573,
    longitude: 66.923684,
    name: "Kazakhstan",
  },
  {
    country: "KW",
    latitude: 29.31166,
    longitude: 47.481766,
    name: "Kuwait",
  },
  {
    country: "LV",
    latitude: 56.879635,
    longitude: 24.603189,
    name: "Latvia",
  },
  {
    country: "LB",
    latitude: 33.854721,
    longitude: 35.862285,
    name: "Lebanon",
    offsetX: 3,
  },
  {
    country: "LY",
    latitude: 26.3351,
    longitude: 17.228331,
    name: "Libya",
  },
  {
    country: "MY",
    latitude: 4.210484,
    longitude: 101.975766,
    name: "Malaysia",
  },
  {
    country: "MT",
    latitude: 35.937496,
    longitude: 14.375416,
    name: "Malta",
  },
  {
    country: "MU",
    latitude: -20.348404,
    longitude: 57.552152,
    name: "Mauritius",
  },
  {
    country: "ME",
    latitude: 42.708678,
    longitude: 19.37439,
    name: "Montenegro",
  },
  {
    country: "MA",
    latitude: 31.791702,
    longitude: -7.09262,
    name: "Morocco",
  },
  {
    country: "NP",
    latitude: 28.394857,
    longitude: 84.124008,
    name: "Nepal",
  },
  {
    country: "AN",
    latitude: 12.226079,
    longitude: -69.060087,
    name: "Netherlands Antilles",
  },
  {
    country: "NZ",
    latitude: -40.900557,
    longitude: 174.885971,
    name: "New Zealand",
  },
  {
    country: "NI",
    latitude: 12.865416,
    longitude: -85.207229,
    name: "Nicaragua",
    offsetX: 5,
  },
  {
    country: "NL",
    latitude: 52.132633,
    longitude: 5.291266,
    name: "Netherlands",
    offsetX: 5,
  },
]

const MapChart = () => {
  const [content, setContent] = useState("")

  return (
    <>
      <ComposableMap
        data-tip=""
        projection="geoEquirectangular"
        projectionConfig={{ scale: 100 }}
        width={600}
        height={300}
        style={{ width: "100%", height: "auto", outline: "none" }}
        onClick={e => e.preventDefault()}
        onMouseDown={e => e.preventDefault()}
        onMouseUp={e => e.preventDefault()}
      >
        <PatternCircles
          id="circle"
          height={4}
          width={4}
          radius={0.8}
          fill={"#7D8E9A"}
          stroke="#7D8E9A"
        />
        <Geographies
          geography={geoUrl}
          onClick={e => e.preventDefault()}
          onMouseDown={e => e.preventDefault()}
          onMouseUp={e => e.preventDefault()}
        >
          {({ geographies }) =>
            geographies.map(geo => {
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onClick={e => e.preventDefault()}
                  onMouseDown={e => e.preventDefault()}
                  onMouseUp={e => e.preventDefault()}
                  fill={"url(#circle)"}
                />
              )
            })
          }
        </Geographies>
        {countries.map((c, i) => (
          <Marker
            key={`marler-${i}`}
            coordinates={[c.longitude, c.latitude]}
            onMouseEnter={() => {
              setContent(c.name)
            }}
            onMouseLeave={() => {
              setContent("")
            }}
          >
            <circle
              cx={c.offsetX || 0}
              r={3}
              fill="#F00"
              stroke="#fff"
              strokeWidth={1}
            />
          </Marker>
        ))}
      </ComposableMap>
      <ReactTooltip className="custom-tooltip">{content}</ReactTooltip>
    </>
  )
}

export default MapChart
